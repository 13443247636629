.popup-fade {
  display: none;
}

@media screen and (width >= 1024px) {
  .hide-desktop {
    display: none !important;
  }

  .popup-fade:before {
    content: "";
    opacity: .7;
    z-index: 9999;
    background-color: #000000bf;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .popup {
    z-index: 99999;
    opacity: 1;
    text-align: left;
    color: var(--black-50);
    background-color: #000000bf;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .popup-frame-group {
    background-color: #1a1a1a;
    border-radius: 20px;
    width: 896px;
    padding: 26px 24px;
    position: relative;
  }

  .popup-download-buttons-parent {
    gap: var(--gap-5xl);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    display: flex;
  }

  .popup-download-buttons {
    backdrop-filter: blur(8px);
    border-radius: var(--br-13xl);
    background-color: #ffffff1a;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    display: flex;
  }

  .popup-frame-icon3, .popup-frame-icon4 {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    min-height: 16px;
    position: relative;
    overflow: hidden;
  }

  .popup-frame-wrapper {
    text-align: center;
    width: 600px;
    margin: 0 auto;
    font-size: 20px;
    display: flex;
  }

  .popup-platform-parent {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 26px auto 0;
    display: flex;
  }

  .popup-platform-h3 {
    font-size: inherit;
    color: #fff;
    margin: 0;
    font-family: inherit;
    font-weight: 700;
    line-height: 28px;
    position: relative;
  }

  .popup-olimp-gamescom {
    font-size: var(--font-size-sm);
    color: #ccc;
    align-self: stretch;
    margin: 12px auto 0;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  .popup-frame-container-desktop {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 64px;
    max-width: 100%;
    margin: 40px auto;
    display: flex;
    overflow-x: auto;
  }

  .popup-android-instruction-steps {
    gap: var(--gap-5xl);
    color: var(--black-20);
    flex-direction: column;
    flex-shrink: 0;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    display: flex;
  }

  .popup-android-instruction-steps-img {
    flex: 1;
    width: 170.5px;
    max-height: 100%;
    position: relative;
    overflow: hidden;
  }

  .popup-div {
    align-self: stretch;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  .popup-download {
    cursor: pointer;
    border-radius: var(--br-13xl);
    justify-content: center;
    align-items: center;
    gap: var(--gap-9xs);
    background-color: #f7fffa;
    border: 0;
    flex-direction: row;
    margin: 0 auto;
    padding: 12px 20px;
    transition: all .5s;
    display: flex;
    box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
  }

  .popup-download:hover {
    cursor: pointer;
    background: #d7eedf;
    box-shadow: 0 0 4px #00000080;
  }

  .popup-download:active {
    background-color: var(--black-0);
    box-shadow: none;
  }

  .popup-frame-icon7 {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    min-height: 20px;
    position: relative;
    overflow: hidden;
  }

  .popup-button-download {
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    font-family: var(--font-inter);
    color: #0d0d0d;
    text-align: center;
    min-width: 71px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .popup-qr-code {
    border-radius: var(--br-13xl);
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 19px;
    display: flex;
    overflow: hidden;
  }

  .popup-frame-container-ios {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    max-width: 100%;
    margin: 65px auto;
    display: flex;
    overflow-x: auto;
  }

  .popup-frame-close {
    cursor: pointer;
    background: #ffffff1a;
    border-radius: 32px;
    width: 20px;
    height: 20px;
    padding: 8px;
    position: absolute;
    top: -36px;
    right: -36px;
  }

  .platform {
    flex-direction: column;
    height: 100%;
    display: flex;
  }
}

@media screen and (width <= 1023px) {
  .hide-mobile {
    display: none !important;
  }

  .popup {
    z-index: 99999;
    opacity: 1;
    text-align: left;
    background-color: #1a1a1a;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .popup-frame-group {
    text-align: left;
    font-size: var(--font-size-sm);
    color: gray;
    font-family: var(--font-inter);
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    padding: 32px 12px;
    display: flex;
  }

  .popup-download-buttons {
    backdrop-filter: blur(8px);
    background: #ffffff1a;
    border-radius: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    display: flex;
  }

  .popup-frame-wrapper {
    text-align: center;
    color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    font-size: 18px;
    display: flex;
  }

  .popup-platform-parent {
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: 12px;
    display: flex;
  }

  .popup-platform-h3 {
    text-align: center;
    color: #fff;
    align-self: stretch;
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 26px;
    position: relative;
  }

  .popup-olimp-gamescom {
    font-size: var(--font-size-sm);
    color: #ccc;
    width: 343px;
    margin: 0;
    font-weight: 500;
    line-height: 20px;
  }

  .popup-download {
    cursor: pointer;
    border-radius: var(--br-13xl);
    justify-content: center;
    align-items: center;
    gap: var(--gap-9xs);
    background-color: #f7fffa;
    border: 0;
    flex-direction: row;
    padding: 12px 20px;
    display: flex;
    box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
  }

  .popup-download:hover {
    cursor: pointer;
    background: #d7eedf;
    transition: all .5s;
    box-shadow: 0 0 4px #00000080;
  }

  .popup-download:active {
    background-color: var(--color-mintcream);
    box-shadow: none;
  }

  .platform {
    letter-spacing: normal;
    box-sizing: border-box;
    background-color: #1a1a1a;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    width: 100%;
    line-height: normal;
    display: flex;
    position: relative;
  }

  .popup-button-download {
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    font-family: var(--font-inter);
    color: #0d0d0d;
    text-align: center;
    min-width: 71px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .popup-frame-container-mobile {
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: var(--gap-5xl);
    text-align: center;
    font-size: var(--font-size-sm);
    color: #ccc;
    font-family: var(--font-inter);
    flex-direction: column;
    padding: 0 2px;
    display: flex;
  }

  .popup-android-instruction-steps {
    border: 1px solid #333;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .popup-qr-code {
    border-radius: var(--br-13xl);
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 19px;
    display: flex;
    overflow: hidden;
  }

  .popup-frame-container-ios {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 100%;
    display: flex;
    overflow-x: auto;
  }

  .popup-frame-close {
    background: #ffffff1a;
    border-radius: 32px;
    width: 20px;
    height: 20px;
    padding: 8px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .popup-frame-container-mobile {
    position: relative;
  }

  .popup-android-instruction-steps {
    height: 100%;
    max-height: 343px;
  }

  .popup-android-instruction-steps-img {
    object-fit: cover;
    object-position: bottom;
    width: 266px;
    height: 100%;
    max-height: 300px;
  }

  .popup-android-instruction-steps-2 {
    & img {
      object-position: center;
    }
  }

  .popup-android-instruction-steps-3 {
    & img {
      object-position: top;
    }
  }

  .plattforms, .platform {
    height: 100%;
  }

  .popup-download-ios {
    position: absolute;
    bottom: 0;
  }

  .swiper {
    width: 343px;
    height: 429px;
    overflow: hidden auto !important;
  }

  :root {
    --swiper-pagination-color: #fff;
    --swiper-pagination-bullet-inactive-color: #ffffff1a;
    --swiper-pagination-bottom: 60px;
  }

  .popup-div {
    position: absolute;
    bottom: 0;
  }

  .swiper-autoheight, .swiper-autoheight .swiper-slide {
    height: auto;
  }
}

.popup-platform-name {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 16px;
  font-family: var(--font-inter);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.popup-platform-buttons .popup-frame-icon3 path {
  fill: gray;
}

.popup-platform-buttons.active .popup-frame-icon3 path {
  fill: #fff;
}

.popup-platform-buttons {
  padding: var(--padding-3xs) var(--padding-xl);
  border-radius: var(--br-13xl);
  justify-content: center;
  align-items: center;
  gap: var(--gap-9xs);
  color: gray;
  cursor: pointer;
  flex-direction: row;
  display: flex;
}

.popup-platform-buttons.active {
  color: #fff;
  border-radius: var(--br-13xl);
  background: #ffffff1a;
  border-radius: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.buttons-mobile {
  backdrop-filter: blur(24px);
  background: #000000bf;
  border-top: 1px solid #fff3;
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin: 0 12px;
  padding: 0 2px;
  display: flex;
}

.buttons-mobile-text {
  color: #fff;
  text-align: center;
  padding: 0 32px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.buttons-mobile-platforms {
  padding: 0 2px;
}

.buttons-mobile .frame-wrapper {
  width: 52px;
  height: 52px;
}

.buttons-mobile .frame-wrapper .frame-icon {
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=en.051b5a79.css.map */
