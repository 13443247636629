.popup-fade {
  display: none;
}
@media screen and (min-width: 1024px) {
  .hide-desktop {
    display: none !important;
  }

  .popup-fade:before {
    content: '';
    background-color: #000000bf;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 9999;
  }
  .popup {
    z-index: 99999;
    opacity: 1;
    text-align: left;
    color: var(--black-50);
    background-color: #000000bf;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .popup-frame-group {
    background-color: #1a1a1a;
    border-radius: 20px;
    width: 896px;
    padding: 26px 24px;
    position: relative;
  }
  .popup-download-buttons-parent {
    gap: var(--gap-5xl);
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  .popup-download-buttons {
    backdrop-filter: blur(8px);
    padding: 2px;
    border-radius: var(--br-13xl);
    background-color: #ffffff1a;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .popup-frame-icon3 {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    min-height: 16px;
    position: relative;
    overflow: hidden;
  }
  .popup-frame-icon4 {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    min-height: 16px;
    position: relative;
    overflow: hidden;
  }

  .popup-frame-wrapper {
    text-align: center;
    width: 600px;
    font-size: 20px;
    display: flex;
    margin: 0 auto;
  }
  .popup-platform-parent {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin: 26px auto 0;
  }
  .popup-platform-h3 {
    font-size: inherit;
    font-family: inherit;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    position: relative;
    color: #fff;
  }

  .popup-olimp-gamescom {
    font-size: var(--font-size-sm);
    color: #ccc;
    align-self: stretch;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    position: relative;
    margin: 12px auto 0;
  }
  .popup-frame-container-desktop {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 64px;
    max-width: 100%;
    display: flex;
    overflow-x: auto;
    margin: 40px auto;
  }
  .popup-android-instruction-steps {
    gap: var(--gap-5xl);
    color: var(--black-20);
    flex-direction: column;
    flex-shrink: 0;
    width: 240px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .popup-android-instruction-steps-img {
    flex: 1;
    width: 170.5px;
    max-height: 100%;
    position: relative;
    overflow: hidden;
  }
  .popup-div {
    align-self: stretch;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }
  .popup-download {
    margin: 0 auto;
    cursor: pointer;
    padding: 12px 20px;
    background-color: #f7fffa;
    border-radius: var(--br-13xl);
    justify-content: center;
    align-items: center;
    gap: var(--gap-9xs);
    border: 0;
    flex-direction: row;
    display: flex;
    box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
    transition: all 0.5s ease;
  }
  .popup-download:hover {
    cursor: pointer;
    box-shadow: 0px -2px 0px 0px #00000040 inset;
    box-shadow: 0px 0px 4px 0px #00000080;
    background: #d7eedf;
  }

  .popup-download:active {
    background-color: var(--black-0);
    box-shadow: none;
  }

  .popup-frame-icon7 {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    min-height: 20px;
    position: relative;
    overflow: hidden;
  }
  .popup-button-download {
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    font-family: var(--font-inter);
    color: #0d0d0d;
    text-align: center;
    min-width: 71px;
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  .popup-qr-code {
    border-radius: var(--br-13xl);
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 19px;
    display: flex;
    overflow: hidden;
  }
  .popup-frame-container-ios {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    max-width: 100%;
    margin: 65px auto;
    display: flex;
    overflow-x: auto;
  }
  .popup-frame-close {
    cursor: pointer;
    border-radius: 32px;
    background: #ffffff1a;
    position: absolute;
    right: -36px;
    top: -36px;
    width: 20px;
    height: 20px;
    padding: 8px;
  }
  .plattforms {
  }
  .platform {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 1023px) {
  .hide-mobile {
    display: none !important;
  }
  .popup {
    z-index: 99999;
    opacity: 1;
    text-align: left;
    background-color: #1a1a1a;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .popup-frame-group {
    padding: 0 2px;
    gap: 24px;
    text-align: left;
    font-size: var(--font-size-sm);
    color: gray;
    font-family: var(--font-inter);
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    display: flex;
    padding: 32px 12px;
  }
  .popup-download-buttons {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    border-radius: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    backdrop-filter: blur(8px);
    padding: 2px;
  }

  .popup-frame-wrapper {
    text-align: center;
    color: #fff;
    font-size: 18px;
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    display: flex;
  }
  .popup-platform-parent {
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    display: flex;
    gap: 12px;
  }
  .popup-platform-h3 {
    align-self: stretch;
    line-height: 26px;
    position: relative;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .popup-olimp-gamescom {
    font-size: var(--font-size-sm);
    color: #ccc;
    margin: 0;
    font-weight: 500;
    line-height: 20px;
    width: 343px;
  }

  .popup-download {
    cursor: pointer;
    padding: 12px 20px;
    border-radius: var(--br-13xl);
    justify-content: center;
    align-items: center;
    gap: var(--gap-9xs);
    background-color: #f7fffa;
    border: 0;
    flex-direction: row;
    display: flex;
    box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
  }

  .popup-download:hover {
    cursor: pointer;
    box-shadow: 0px -2px 0px 0px #00000040 inset;
    box-shadow: 0px 0px 4px 0px #00000080;
    background: #d7eedf;
    transition: all 0.5s ease;
  }

  .popup-download:active {
    background-color: var(--color-mintcream);
    box-shadow: none;
  }

  .platform {
    width: 100%;
    letter-spacing: normal;
    background-color: #1a1a1a;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    line-height: normal;
    position: relative;
    box-sizing: border-box;
    justify-content: flex-start;
    display: flex;
  }

  .popup-button-download {
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    font-family: var(--font-inter);
    color: #0d0d0d;
    text-align: center;
    min-width: 71px;
    display: inline-block;
    position: relative;
    text-decoration: none;
  }

  .popup-frame-container-mobile {
    padding: 0 2px;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: var(--gap-5xl);
    text-align: center;
    font-size: var(--font-size-sm);
    color: #ccc;
    font-family: var(--font-inter);
    flex-direction: column;
    display: flex;
  }

  .popup-android-instruction-steps {
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    display: flex;
    border-radius: 16px;
    border: 1px solid rgba(51, 51, 51, 1);
  }
  .popup-qr-code {
    border-radius: var(--br-13xl);
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 19px;
    display: flex;
    overflow: hidden;
  }
  .popup-frame-container-ios {
    box-sizing: border-box;
    text-align: center;
    color: #e6e6e6;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 100%;
    display: flex;
    overflow-x: auto;
  }
  .popup-frame-close {
    border-radius: 32px;
    background: #ffffff1a;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    padding: 8px;
  }

  .popup-frame-container-mobile {
    position: relative;
  }
  .popup-android-instruction-steps {
    max-height: 343px;
    height: 100%;
  }

  .popup-android-instruction-steps-img {
    height: 100%;
    max-height: 300px;
    object-fit: cover;
    width: 266px;
    object-position: bottom;
  }
  .popup-android-instruction-steps-2 {
    img {
      object-position: center;
    }
  }
  .popup-android-instruction-steps-3 {
    img {
      object-position: top;
    }
  }
  .plattforms,
  .platform {
    height: 100%;
  }
  .popup-download-ios {
    position: absolute;
    bottom: 0;
  }

  .swiper {
    width: 343px;
    height: 429px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  :root {
    --swiper-pagination-color: #ffffff;
    --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.1);
    --swiper-pagination-bottom: 60px;
  }

  .popup-div {
    position: absolute;
    bottom: 0px;
  }

  .swiper-autoheight,
  .swiper-autoheight .swiper-slide {
    height: auto;
  }
}

.popup-platform-name {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 16px;
  font-family: var(--font-inter);

  text-align: left;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.popup-platform-buttons .popup-frame-icon3 path {
  fill: #808080;
}
.popup-platform-buttons.active .popup-frame-icon3 path {
  fill: #fff;
}
.popup-platform-buttons {
  padding: var(--padding-3xs) var(--padding-xl);
  border-radius: var(--br-13xl);
  justify-content: center;
  align-items: center;
  gap: var(--gap-9xs);
  flex-direction: row;
  display: flex;
  color: #808080;
  cursor: pointer;
}
.popup-platform-buttons.active {
  color: #fff;
  border-radius: var(--br-13xl);
  background-color: #ffffff1a;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.1);
}

.buttons-mobile {
  height: 52px;
  padding: 0 2px;
  border-radius: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(24px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
  height: 60px;
}
.buttons-mobile-text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0 32px;
}
.buttons-mobile-platforms {
  padding: 0 2px;
}

.buttons-mobile .frame-wrapper {
  width: 52px;
  height: 52px;
}
.buttons-mobile .frame-wrapper .frame-icon {
  width: 20px;
  height: 20px;
}
